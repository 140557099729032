import React from "react";
import { Button, ButtonVariant } from "brass-ui-kit";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./Contact.module.scss";
import { BRASS_APPOINTMENT_BOOKING_URL } from "data/config";

const Contact: React.FC = () => {
  return (
    <section className={styles.Contact}>
      <div className={styles.Contact_container}>
        <div className={styles.Contact_image}>
          <StaticImage
            src="../../../assets/media/images/contact.png"
            alt="Picture of an office space"
            as="span"
          />
        </div>
        <header className={styles.Contact_header}>
          <h2 className={styles.Contact_header_title}>Contact us</h2>
          <p>
            We are a digital-first company. Even though we have an always-open
            office, it is very important you let us know before you visit.
            <br />
            <br />
            <br />
            <u>21B Modupe Odulami Street, Oniru</u>
          </p>
          <Button
            as="a"
            variant={ButtonVariant.Primary}
            label="Book an appointment"
            to={BRASS_APPOINTMENT_BOOKING_URL}
            target="_blank"
          />
        </header>
      </div>
    </section>
  );
};

export default Contact;
